import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { AuthState, User } from "./types";

export const mutations: MutationTree<AuthState> = {
  [MutationTypes.SET_AUTH_INFO](state, payload: any) {
    if (payload == null) {
      state.user = undefined;
      state.error = true;
    }
    state.user = payload;
    state.error = false;
  },
  // [MutationTypes.REFRESH_TOKEN](state, payload: any) {
  // },
  [MutationTypes.LOGOUT_USER](state) {
    state.user = undefined;
    localStorage.removeItem("authToken");
    localStorage.removeItem("kcToken");
  },
  [MutationTypes.SET_SESSION_TOKEN](state, payload) {
    if (state.user) {
      state.user.sessionId = payload;
    }
  },
  // [MutationTypes.SET_SHIPSITE_TOKEN](state, payload) {
  //   if (state.user) {
  //     state.user.shipsiteToken = payload["token"];
  //     localStorage.setItem("shipsiteToken", payload["token"]);
  //   }
  // },
  // [MutationTypes.CLEAR_SHIPSITE_TOKEN](state) {
  //   if (state.user) {
  //     state.user.shipsiteToken = "";
  //     localStorage.removeItem("shipsiteToken");
  //   }
  // },
};
