import { DataState } from "@/services/data-states";
import Vue from "vue";
import { MutationTree } from "vuex";
import { BufferMutations } from "./mutation-types";
import { BufferState } from "./types";

export const mutations: MutationTree<BufferState> = {
  [BufferMutations.SET_START_INDEX](state, payload) {
    state.currIndex = payload;
  },
  [BufferMutations.SET_START_END_INDEX](state) {
    state.currIndex = state.endIndex + 1;
    state.endIndex = state.currIndex + 7;
  },
  [BufferMutations.SET_BUFFER](state, payload: any) {
    const id = Object.keys(payload)[0] as string;
    const value = payload[id];
    Vue.set(state.bufferDetails, id, value);
  },
  [BufferMutations.SET_BUFFER_STATE](state, payload: DataState) {
    state.bufferState = payload;
  },
  [BufferMutations.SET_BUFFER_LOADING](state, payload: boolean) {
    state.bufferLoading = payload;
  },
  [BufferMutations.SET_BUFFER_LOADED](state, payload: boolean) {
    state.bufferLoaded = payload;
  },
  [BufferMutations.ADD_BUFFER_STEP](state) {
    state.bufferStep = state.bufferStep + 1;
  },
  [BufferMutations.SET_BUFFER_STEP](state, payload: number) {
    state.bufferStep = payload;
  },
  [BufferMutations.ADD_TO_LOADING_IDS](state, payload) {
    state.currentlyLoadingIds.set(payload.id, {
      hasOrder: payload.hasOrder,
      orderId: payload.orderId
    });
  },
  [BufferMutations.REMOVE_FROM_LOADING_IDS](state, id) {
    const _map = state.currentlyLoadingIds;
    _map.delete(id);
  },
  [BufferMutations.RESET_BUFFER](state) {
    state.bufferDetails = {};
    state.bufferStep = 0;
    state.bufferLoaded = false;
    state.bufferLoading = false;
  },
  [BufferMutations.SET_BUFFER_ERROR_MESSAGE](state, msg: string) {
    state.bufferErrorMessage = msg;
  },
  [BufferMutations.SET_BUFFER_ERROR_STATE](state, payload: boolean) {
    state.bufferLoadingError = payload;
  }
  // [BufferMutations.SET_IS_NEXT_PAGE](state, payload: boolean) {
  //   state.isNextPage = payload;
  // },
};
