
// import MainLayout from "./layout/MainLayout.vue";
import { io } from "socket.io-client";
import Vue from "vue";
import StaffApi from "@/api/staff";
import { TreeActions } from "@/store/general/action-types";
import MessageActionTypes from "@/store/messages/action-types";
import { ActionTypes } from "@/store/auth/action-types";
import PageError from "./components/PageError.vue";
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
  name: "LayoutDefault",

  components: {
    PageError,
  },

  data() {
    return {
      initialized: false,
      isOnline: true,
      loadingError: false,
    };
  },
  computed: {
    ...mapGetters({
      isManager: "Auth/isManager",
    }),
    // shipsiteToken(): any {
    //   return this.$store.state.Auth.shipsiteToken;
    // },
  },
  methods: {
    ...mapActions({
      shipsiteLogin: "Auth/" + ActionTypes.GET_SHIPSITE_TOKEN,
    }),
    init() {
      this.$store.dispatch("General/" + TreeActions.INIT);
      this.$store
        .dispatch("Message/" + MessageActionTypes.ActionTypes.INIT, {
          isManager: +this.isManager,
          showAll: 0,
        })
        .then(() => {
          console.log("it is my fault! APP");
          this.initialized = true;
        })
        .catch((err) => {
          this.initialized = true;
          this.loadingError = true;
          console.log(err);
        });
    },
    setupSocket() {
      console.log("Set Up Socket");
      const socket = io();
      socket.on("json", (data) => {
        console.log("DSone");
        if (data.for && data.for == "frontend") {
          let type = "info";
          if (data.type) {
            type = data.type;
            if (type == "warn") {
              type = "warning";
            }
            if (type == "success") {
              type = "positive";
            }
            if (type == "info") {
              type = "primary";
            }
          }
          const msg =
            type +
            ":" +
            new Date().toLocaleTimeString("en-US") +
            ": " +
            data.data.msg;

          this.$q.notify({
            message: msg,
            color: type,
            icon: "settings_suggest",
          });
        }
      });
    },
    checkOnlineStatus() {
      window.addEventListener("offline", () => {
        this.isOnline = false;
      });
    },
    setTheme(mode: string) {
      if (mode == "light") {
        return this.$q.dark.set(false);
      }
      this.$q.dark.set(true);
    },
  },
  created() {
    // const timeToRefresh = 10 * 60 * 1000; // 10 mins
    const token = localStorage.getItem("authToken");
    setInterval(this.checkOnlineStatus, 2000);
    const mode = localStorage.getItem("theme");
    if (mode) {
      this.setTheme(mode);
    }
    if (token) {
      this.$store
        .dispatch("Auth/GET_USER")
        .then(() => {
          this.init();
        })
        .catch((err) => {
          console.log("User auth issue");
          this.initialized = true;
          this.loadingError = true;
          console.log(err);
        });
    } else {
      this.initialized = true;
    }
    const shipsiteToken = localStorage.getItem("shipsiteToken");
    // if (!shipsiteToken) {
    //   StaffApi.setUpShipsiteToken();
    // }
    // if (!shipsiteToken) {
    //   StaffApi.setUpShipsiteToken().catch((error) => {
    //     console.log("App Unable to Setup Shipsite Token");
    //     console.log(error);
    //   });
    // }
  },
});
