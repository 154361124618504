import Util from "./util";

const axios = Util.createAxiosInstance("/api/manager");

export default {
  users() {
    return new Promise((resolve, reject) => {
      const url = "/users";
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  postOperatorName(data: any) {
    return new Promise((resolve, reject) => {
      const url = "/update_operator_name";
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setStat(data: { actionType: string; sessionToken?: string; userId: string }) {
    return new Promise((resolve, reject) => {
      const url = `/session_info/${data.userId}`;
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getSessionStats(data: {
    dateFilter: { to: string; from: string };
    userId?: number;
  }) {
    return new Promise((resolve, reject) => {
      const url = `/get_session_stats/${data.userId}`;
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
