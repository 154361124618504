import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { MessageState } from "@/store/messages/types";
import { state } from "../auth";

export const getters: GetterTree<MessageState, RootState> = {
  allMessages: (state) => {
    const allMessageasArray = state.filteredMessages.filter((item: any) => {
      return state.handledMessageIds.indexOf(item.id) <= -1;
    });
    return allMessageasArray;
  },
  // messagesByAccount:(state){},
  handledMessageIds(state) {
    return state.handledMessageIds;
  },
  readMessageIds(state) {
    return state.readMessages;
  },
  getMessages(state) {
    return state.messages;
  },
  isNextPage: (state) => {
    return state.isNextPage;
  },
  messageById: (state) => (id: string) => {
    return state.filteredMessages.find((message) => message.id === id);
  },
  getBuffer: (state) => {
    return state.bufferDetails;
  },
  getBufferLength: (state) => {
    return state.bufferDetails.length;
  },
  getBufferLoadedState: (state) => {
    return state.bufferLoaded;
  },
  getBufferState: (state) => {
    return state.bufferState;
  },
  getBufferLoading: (state) => {
    return state.bufferLoading;
  },
  getBufferStep: (state) => {
    return state.bufferStep;
  },
  lastIdx: (state) => {
    const index = state.filteredMessages.length - 1;
    return index;
  },
  getCurrIndex: (state) => (mId: string) => {
    const currIdx = state.filteredMessages.findIndex(({ id }) => {
      return id === mId;
    });
    return currIdx;
  },
  getNextID: (state, getters) => (mId: string) => {
    const currentIndex = state.filteredMessages.findIndex(({ id }) => {
      return id === mId;
    });
    if (currentIndex === -1) {
      return -1;
    }
    if (currentIndex == getters.lastIdx) {
      return -1;
    }
    const nextIndex = currentIndex + 1;
    return state.filteredMessages[nextIndex].id;
  },
  getPrevID: (state) => (mId: string) => {
    const currentIndex = state.filteredMessages.findIndex(({ id }) => {
      return id === mId;
    });
    if (currentIndex === -1) {
      return "";
    }
    const prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      return "";
    }
    const prevID = state.filteredMessages[prevIndex].id;
    return prevID;
  },
  getNextTwo: (state, getters) => (id: string) => {
    const startIdx = getters.getCurrIndex(id) + 1;
    const endIdx = startIdx + 1;
    const buffer = state.messages.slice(startIdx, endIdx);
    return buffer;
  },
  getIdsToLoad: (state, getters) => (id: string) => {
    const startIdx = getters.getCurrIndex(id);
    const endIdx = startIdx + 1;
    const buffer = state.filteredMessages.slice(startIdx, endIdx);
    return buffer;
  },
  nextMessage: (state, getters) => (id: string) => {
    const currIdx = state.filteredMessages.findIndex((item) => {
      if (item.id == id) {
        return true;
      }
    });
    const endofArray = getters.lastIdx;
    console.log(currIdx + "" + endofArray);
    if (currIdx < endofArray) {
      const nextObj = state.filteredMessages[currIdx + 1];
      return nextObj;
    }
    return -1;
  },
  prevMessage: (state) => (id: string) => {
    const currIdx = state.messages.findIndex((item) => {
      if (item.id == id) {
        return true;
      }
    });
    const endofArray = state.messages.length - 1;
    if (currIdx >= 0) {
      const prevIdx = currIdx - 1;
      const nextObj = state.messages[prevIdx];
      if (nextObj != undefined) {
        return nextObj;
      } else {
        return -1;
      }
    }
    return currIdx;
  },

  loadedMessages(state) {
    return state.loadedMessages;
  },
  unreadMessages(state, getters) {
    if (state.filteredMessages != null) {
      return getters.allMessages;
    }
    return [];
  },
  wasRead(state) {
    const readMessages = state.messages.filter((item) => {
      return item.isRead;
    });
    return readMessages.length;
  },
  hasOrder(state, filter: any) {
    return state.filteredMessages.filter((item) => {
      return item.hasOrder == true;
    });
  },
  noOrder(state) {
    return state.filteredMessages.filter((item) => {
      return item.hasOrder == false;
    });
  },
  unreadMessagesCount(state) {
    // const count = state.unreadCount;
    // const read = state.readCount;
    const allMessagesLoaded = state.messages.length;
    // const toRead = count + allMessagesLoaded;
    // const diff = allMessagesLoaded - read;
    return allMessagesLoaded;
    // if (diff < 0) {
    //   return 0;
    // }
    // return diff;
  },
  allCount(state) {
    return state.messages.length;
  },
  quickTemplates(state) {
    return state.quickTemplates;
  },
  quickTemplatesLoaded(state) {
    return state.loadedTemplates;
  },
  nextId: (state) => (id: string) => {
    const currIdx = state.messages.findIndex((el) => {
      el.id == id;
    });
    if (currIdx != -1) {
      return -1;
    }
    const nextId = state.messages[currIdx + 1].id;
    return nextId;
  },
  prevId: (state) => (id: string) => {
    const currIdx = state.messages.findIndex((el) => {
      return el.id == id;
    });
    if (currIdx != -1) {
      return -1;
    }
    const prevId = state.messages[currIdx - 1].id;
    return prevId;
  },
  getOutboundQueue(state) {
    return state.outboundQueue;
  },
  getOutboundProcessedCount: (state) => {
    let oqObj = [];
    // if (processed) {
    oqObj = state.outboundQueue.filter((el: any) => {
      return el.type_.name == "send_message" && el.processed == false;
    });
    const count = oqObj.length;
    return count;
    // }
    // oqObj = state.outboundQueue.filter((el: any) => {
    //   return el.type_.name == "send_message" && el.processed == false;
    // });
    // const count = oqObj.length;
    // return count;
  },
  outboundLoaded(state) {
    return state.outboundLoaded;
  },
  isInMessages: (state) => (id: string) => {
    {
      const index = state.messages.findIndex((el) => {
        el.id == id;
      });
      if (index != -1) {
        return false;
      } else return true;
    }
  },
  orderIdInMessageList: (state) => (payload: string) => {
    console.log(payload);
    const index = state.messages.find(({ orderId }) => {
      return orderId === payload;
    });
    console.log(index);
    if (index) {
      return true;
    } else {
      return false;
    }
  },
  messageIdByOrderID: (state) => (payload: string) => {
    const message = state.messages.find(({ orderId }) => {
      return orderId === payload;
    });
    if (message) {
      console.log("Message Found");
      return message["id"];
    }
  },
};
