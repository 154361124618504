import BaseAxios from "axios";
import Util from "./util";
import shipsiteAuthApi from "@/api/shipping-api-cors/auth";
import AccountAbbrev from "@/services/AccountAbbrev";
import { tokenExpired } from "@/services/token-helper";
const axios = Util.createAxiosInstance("/api/staff");
enum ItemType {
  Message = "message",
  Order = "order",
}
enum actionType {
  ORDER_ACTION = "oa",
  OUTBOUND_QUEUE = "oq",
}

export default {
  ItemType,
  AccountAbbrev,
  actionType,
  outBoundQueue(page: number, limit: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/outBoundQueue?page=${page}&limit=${limit}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  inventoryConfigs() {
    return new Promise((resolve, reject) => {
      const url = `/inventoryConfigs`;
      axios
        .get(url)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  allUnread(filter = { isManager: 0, showAll: 0, accountAbbrev: "KC" }) {
    return new Promise((resolve, reject) => {
      const url = `/allUnread`;
      axios
        .get(url, { params: filter })
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  //
  apiOrderInfo(accountAbbrev: AccountAbbrev, orderId: string) {
    return new Promise((resolve, reject) => {
      axios

        .get(`/apiInfo/${accountAbbrev}/${orderId}`)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getOrder(orderId: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/getOrder/${orderId}`)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getPage(
    accountAbbrev: AccountAbbrev,
    type: ItemType,
    messageId: string,
    orderId: any,
  ) {
    return new Promise((resolve, reject) => {
      let url = `/page/${accountAbbrev}/${type}/${messageId}`;
      console.log(url);
      if (orderId) {
        url += `/${orderId}`;
      }

      axios
        .get(url)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getAllReplies() {
    return axios.get("/replies");
  },
  getSuggestionsForMessage(
    accountAbbrev: AccountAbbrev,
    type: ItemType,
    messageId: string,
    orderId: any = null,
  ) {
    // returns a list of strings [r1, r2]
    let url = `/suggestionsForMessage/${accountAbbrev}/${type}/${messageId}`;
    if (orderId) {
      url += "/" + orderId;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response: any) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSubstitutionInReply(
    type: ItemType,
    replyId: string,
    messageId: string,
    orderId: string,
    isQuickTemplate: boolean,
    accountAbbrev: string,
  ) {
    const url = `/substitute_in_template/${type}/${replyId}/${accountAbbrev}`;
    const data: any = {
      messageId: messageId,
      isQuickTemplate: isQuickTemplate,
    };
    if (orderId) {
      data["order_id"] = orderId;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getHandledMessages() {
    return axios.get("/handledMessages").then((response) => {
      return response.data.message_ids;
    });
  },
  showedAddress(orderId: string) {
    return axios.post(`/showedAddress/${orderId}`);
  },
  checkShowedAddress(orderId: string) {
    return axios.get(`/showedAddress/${orderId}`);
  },
  sendMessage(
    type: ItemType,
    accountAbbrev: AccountAbbrev,
    messageId: string,
    orderId: any = null,
    message: string,
    replyTemplate: string,
    uuid: string,
    shouldDelete: false,
  ) {
    return new Promise((resolve, reject) => {
      let url = `/page/${accountAbbrev}/${type}/${messageId}`;
      if (orderId) {
        url += `/${orderId}`;
      }
      const data = {
        messageId: messageId,
        orderId: orderId,
        message: message,
        replyTemplate: replyTemplate,
        shouldDelete: shouldDelete,
        uuid: uuid,
      };
      resolve(data);
      return axios
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assignToAdmin(messageId: string) {
    return axios.post(`/assignToAdmin/${messageId}`);
  },
  getReadMessages() {
    return axios.get("/readMessages");
  },
  markAsUnread(msgId: string) {
    return axios.delete("/readMessages/" + msgId);
  },
  getOrderInfo(orderId: string) {
    return axios.get("/orderInfo/" + orderId);
  },
  editOrderInfo(orderId: string, data: any) {
    //allowed keys are anything that goes into the model OrderInfo
    return axios.post("/orderInfo/" + orderId, data);
  },
  getQuickTemplate() {
    return axios.get("quickTemplate");
  },
  postQuickTemplate(payload: any) {
    return axios.post("quickTemplate", payload);
  },
  deleteQuickTemplate(payload: any) {
    return axios.delete("/quickTemplate/" + payload.id);
  },
  deleteMessages(uuid: string, accountAbbrev: AccountAbbrev, ids: string[]) {
    // this should now support many IDS, but ALL of the same account
    const data = {
      ids: ids,
      uuid: uuid,
    };
    return axios.post(`/deleteMessages/${accountAbbrev}`, data);
  },
  previousOrders(username: string, accountAbbrev: AccountAbbrev) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/previousOrders/${username}/${accountAbbrev}`)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getMapForActiveListing(itemId: string) {
    const url = `getMapForActiveListingId/${itemId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getMapForSoldOrder(orderId: any) {
    const url = `getMapforSoldOrderId/${orderId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCustomerFields(data: any) {
    const url = `updateCustomerFields`;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          // const jobId = response.data.job_id;
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeFeedback(data: {
    orderID: string;
    uuid: string;
    accountAbbrev: AccountAbbrev;
  }) {
    return new Promise((resolve, reject) => {
      const url = `/removeFeedback/${data.accountAbbrev}`;
      axios
        .post(url, data)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendRefund(data: {
    orderId: string;
    uuid: string;
    amount: number;
    accountAbbrev: AccountAbbrev;
  }) {
    return new Promise((resolve, reject) => {
      const url = `/sendRefund/${data.accountAbbrev}`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  mergeOrders(data: any, accountAbbrev: AccountAbbrev) {
    return new Promise((resolve, reject) => {
      const url = `/mergeOrders/${accountAbbrev}`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelOrder(
    data: { orderId: string; uuid: string; cancelOrderOption: string },
    accountAbbrev: string,
  ) {
    return new Promise((resolve, reject) => {
      const url = `/cancelOrder/${accountAbbrev}`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeShippingService(
    data: {
      orderId: string;
      uuid: string;
      shippingMethod: string;
      shippingPrice: string;
    },
    accountAbbrev: any,
  ) {
    return new Promise((resolve, reject) => {
      const url = `/changeShippingService/${accountAbbrev}`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getShippingMethods(
    data: {
      orderId: string;
      uuid: string;
    },
    accountAbbrev: any,
  ) {
    return new Promise((resolve, reject) => {
      const url = `/getShippingMethods/${accountAbbrev}`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  purgeCache() {
    return new Promise((resolve, reject) => {
      const url = `/purgeCache`;
      axios
        .get(url)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTrackingDetails(trackingNumber: any) {
    return new Promise((resolve, reject) => {
      // starts task and returns job.id to be used to get results
      const url = "/getTrackingDetails";
      const data = {
        trackingNumber: trackingNumber,
      };
      axios
        .post(url, data)
        .then((response) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async checkOutboundQueueSuccess(oqId: string) {
    return new Promise((resolve, reject) => {
      const url = `/checkOutboundQueue/${oqId}`;
      axios
        .get(url)
        .then((response: any) => {
          const responseData = response.data;
          const processed = Boolean(responseData["processed"]);
          const success = Boolean(responseData["success"]);
          if (processed && !success) {
            return reject(responseData);
            // return false;
          }
          if (processed && success) {
            return resolve(responseData);
            // return true;
          }
          setTimeout(() => {
            resolve(this.checkOutboundQueueSuccess(oqId));
          }, 1000);
        })
        .catch((error) => {
          reject(error);
        });
      false;
    });
  },
  checkOrderActionSuccess(actionId: string) {
    return new Promise((resolve, reject) => {
      const url = `/checkOrderActionStatus/${actionId}`;
      axios
        .get(url)
        .then((response) => {
          const responseData = response.data;
          const processed = Boolean(responseData["processed"]);
          const failed = Boolean(responseData["failed"]);

          if (processed && failed) {
            // reject(response);
            return reject(response);
          }
          if (processed && !failed) {
            resolve(true);
            return true;
          }
          setTimeout(() => {
            resolve(this.checkOrderActionSuccess(actionId));
          }, 1000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async checkIfSuccess(type_: actionType, actionId: string) {
    if (type_ == actionType.ORDER_ACTION) {
      const response = await this.checkOrderActionSuccess(actionId);
      return response;
    }
    const response = await this.checkOutboundQueueSuccess(actionId);
    return response;
  },
  getOrderActions(orderId: string) {
    return new Promise((resolve, reject) => {
      if (!orderId) {
        return reject("Invalid Request, Order ID undefinded");
      }
      const url = `/getOrderActions/${orderId}`;
      axios
        .get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOutboundQueue(orderId: any) {
    return new Promise((resolve, reject) => {
      if (!orderId) {
        return reject("Invalid Request, Order ID cannot be undefined.");
      }
      const url = `/getOutboundQueue/${orderId}`;
      axios
        .get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSingleOutboundQueueItem(oqID: any) {
    // For individual OQ items
    return new Promise((resolve, reject) => {
      const url = `/checkOutboundQueue/${oqID}`;
      axios
        .get(url)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchShipsiteToken() {
    return new Promise((resolve, reject) => {
      const url = `/fetchShipsiteToken`;
      axios
        .get(url)
        .then((response: any) => {
          const jobId = response.data.job_id;
          resolve(Util.getTaskResult(jobId));
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
  saveShipsiteTokenToCache(token: string) {
    return new Promise((resolve, reject) => {
      const url = `/saveShipsiteTokenToCache`;
      const data = {
        token: token,
      };
      axios
        .post(url, data)
        .then((response) => {
          const job_id = response.data.job_id;
          resolve(Util.getTaskResult(job_id));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async setUpShipsiteToken() {
    let token = "";
    const cachedToken = await this.fetchShipsiteToken();
    if (!cachedToken) {
      try {
        await shipsiteAuthApi.loginToShipsite();
        return;
      } catch (e) {
        console.log("Unable to login and save robot token");
        console.log(e);
        return;
      }
    }
    token = cachedToken as any;
    const tokenInLocalStorage = localStorage.getItem("shipsiteToken");
    if (!tokenInLocalStorage) {
      localStorage.setItem("shipsiteToken", token);
    }
    return;
  },
};
