export enum CustomerActionTypes {
  LOAD_CUSTOMERS = "LOAD_CUSTOMERS",
  UPDATE_CUSTOMER = "UPDATE_CUSTOMER",
  BULK_UPDATE_CUSTOMER = "BULK_UPDATE_CUSTOMER",
  DELETE_CUSTOMER = "DELETE_CUSTOMER",
  CREATE_CUSTOMER = "CREATE_CUSTOMER",
  LOAD_TASKS = "LOAD_TASKS",
  CREATE_TASK = "CREATE_TASK",
  UPDATE_TASK = "UPDATE_TASK",
  DELETE_TASK = "DELETE_TASK",
  LOAD_TOOLS = "LOAD_TOOLS",
  CREATE_TOOL = "CREATE_TOOL",
  UPDATE_TOOL = "UPDATE_TOOL",
  DELETE_TOOL = "DELETE_TOOL",
}
