import Notify from 'quasar/src/plugins/Notify.js';;
Notify.setDefaults({
  position: "top",
  type: "positive"
});

const Success = (message: string) => {
  Notify.create({
    message: message
  });
};
const Info = (message: string) => {
  Notify.create({
    message: message,
    type: "default",
    icon: "info"
  });
};
const Warn = (message: string) => {
  Notify.create({
    message: message,
    type: "warning",
    icon: "alert"
  });
};
export { Success, Info, Warn };
