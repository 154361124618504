import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { BufferState } from "./types";

export const getters: GetterTree<BufferState, RootState> = {
  getLoadingIds(state) {
    return state.currentlyLoadingIds;
  },
  getCurrIdx(state) {
    return state.currIndex;
  },
  getBufferErrorMessage(state) {
    return state.bufferErrorMessage;
  },
  getBufferErrorState(state) {
    return state.bufferLoadingError;
  },
  getEndIdx(state) {
    return state.endIndex;
  },
  isInLoadingIds: (state) => (id: string) => {
    const check = state.currentlyLoadingIds.has(id);
    return check;
  },
  isInBuffer: (state) => (id: string) => {
    const check = state.bufferDetails[id];
    return check;
  },
  getPageDetails(state) {
    return (routeId: string) => {
      if (state.bufferDetails) {
        const dets = state.bufferDetails[routeId];
        if (dets) {
          return dets;
        }
      }
      return null;
    };
  },
  getBufferLoading(state) {
    return state.bufferLoading;
  },
  getBufferState(state) {
    return state.bufferState;
  },
  getMessageInBuffer(state) {
    return (messageID: string): any => {
      const message = state.bufferDetails[messageID];
      return message;
    };
  },
};
