import Vue from "vue";
import { MutationTree } from "vuex";
import { CustomerMutationTypes } from "./mutation-types";
import { CustomerState } from "./types";

export const mutations: MutationTree<CustomerState> = {
  //  CUSTOMER MUTATIONS
  [CustomerMutationTypes.ADD_CUSTOMER](state, payload: any) {
    state.customers.push(payload);
  },
  [CustomerMutationTypes.SET_CUSTOMERS](state, payload) {
    state.customers = payload;
    if (!state.customers) {
      state.customers = [];
      return (state.customersLoaded = false);
    }
    state.customersLoaded = true;
  },
  [CustomerMutationTypes.UPDATE_CUSTOMERS](state, payload) {
    console.log(payload);
    const index = state.customers.findIndex((customer: any) => {
      return customer.id == payload.id;
    });
    console.log(index);
    if (index != -1) {
      Vue.set(state.customers, index, payload);
      console.log("hehrehrerhr");
    }
  },
  [CustomerMutationTypes.REMOVE_CUSTOMER](state, payload) {
    state.customers = state.customers.filter((item) => {
      return item.id != payload;
    });
  },

  // TASKS MUTATIONS
  [CustomerMutationTypes.SET_TASKS](state, payload: any) {
    state.tasks = payload;
    if (!state.tasks) {
      return (state.tasksLoaded = false);
    }
    state.tasksLoaded = true;
  },
  [CustomerMutationTypes.ADD_TASK](state, payload: any) {
    state.tasks.push(payload);
  },
  [CustomerMutationTypes.UPDATE_TASK](state, payload) {
    console.log(payload);
    const index = state.tasks.findIndex((task: any) => {
      return task.id == payload.id;
    });
    console.log(index);
    if (index != -1) {
      Vue.set(state.tasks, index, payload);
    }
  },
  [CustomerMutationTypes.REMOVE_TASK](state, payload) {
    state.tasks = state.tasks.filter((item) => {
      return item.id != payload;
    });
  },
  //  TOOLS MUTATIONS
  [CustomerMutationTypes.SET_TOOLS](state, payload: any) {
    state.tools = payload;
    if (!state.tools) {
      return (state.toolsLoaded = false);
    }
    state.toolsLoaded = true;
  },
  [CustomerMutationTypes.ADD_TOOL](state, payload: any) {
    state.tools.push(payload);
  },
  [CustomerMutationTypes.UPDATE_TOOL](state, payload) {
    console.log(payload);
    const index = state.tools.findIndex((tool: any) => {
      return tool.id == payload.id;
    });
    console.log(index);
    if (index != -1) {
      Vue.set(state.tools, index, payload);
    }
  },
  [CustomerMutationTypes.REMOVE_TOOL](state, payload) {
    state.tools = state.tools.filter((item) => {
      return item.id != payload;
    });
  },
};
