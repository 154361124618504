export enum CustomerMutationTypes {
  SET_CUSTOMERS = "SET_CUSTOMERS",
  UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS",
  REMOVE_CUSTOMER = "REMOVE_CUSTOMER",
  ADD_CUSTOMER = "ADD_CUSTOMER",
  SET_TASKS = "SET_TASKS",
  UPDATE_TASK = "UPDATE_TASK",
  ADD_TASK = "ADD_TASK",
  REMOVE_TASK = "REMOVE_TASK",
  SET_TOOLS = "SET_TOOLS",
  UPDATE_TOOL = "UPDATE_TOOL",
  ADD_TOOL = "ADD_TOOL",
  REMOVE_TOOL = "REMOVE_TOOL",
}
