import BaseAxios from "axios";
import {
  getToken,
  refreshToken,
  removeToken,
  tokenExpired,
  getRefreshToken,
} from "@/services/token-helper";
import { config } from "@vue/test-utils";

function createAxiosInstance(baseURL = "/api") {
  const axios = BaseAxios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (
        error.response === null ||
        error.response === undefined ||
        error.response.status != 401
      ) {
        return Promise.reject(error);
      }

      // we are only dealing with 401 errors here
      return refreshToken()
        .then((res: any) => {
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${res}`;
          return axios.request(config); // the original request handling that will still follow the same chain
        })
        .catch((error) => {
          localStorage.removeItem("authToken");
          localStorage.setItem("path", window.location.href);
          window.location.reload();
        });
    },
  );
  // axios.interceptors.response.use(
  //   function(response) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return response;
  //   },
  //   function(error) {
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //     if (error.response != null && error.response.status == 401) {
  //       const detail = error.response.data.detail;
  //       localStorage.removeItem("authToken");
  //       localStorage.setItem("path", window.location.href);
  //       window.location.reload();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  return axios;
}

const axios = createAxiosInstance();

function getTaskResult(taskId: string) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/redisResult/${taskId}`)
      .then((response: any) => {
        const status = response.data.state;
        if (status === "FAILURE") {
          reject(response.data.exc_info);
          return;
        }
        if (status === "SUCCESS") {
          const result = response.data.result;
          resolve(result);
          return;
        }
        setTimeout(() => {
          resolve(getTaskResult(taskId));
        }, 1000);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default {
  createAxiosInstance,
  getTaskResult,
};
