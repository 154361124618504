import api from "@/api/index";
import { ActionTree } from "vuex";
import { TreeActions } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { TemplateTree } from "./types";
import { RootState } from "@/store/types";

export const actions: ActionTree<TemplateTree, RootState> = {
  [TreeActions.INIT](actions) {
    return new Promise((resolve, reject) => {
      actions.dispatch(TreeActions.LOAD_TEMPLATE_TREE);
      actions.dispatch(TreeActions.LOAD_NEW_REPLIES);
      actions.dispatch(TreeActions.GET_INVENTORY_CONFIGS);
    });
  },
  // [TreeActions.LOAD_TEMPLATE_TREE]({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     api.axios
  //       .get("/templateTree")
  //       .then((response: any) => {
  //         commit(MutationTypes.SET_TEMPLATE_TREE, response.data);
  //         resolve(response.data);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  [TreeActions.LOAD_TEMPLATE_TREE]({ commit }) {
    return new Promise((resolve, reject) => {
      api.MessageTemplates.getTree()
        // .get("/templateTree")
        .then((response: any) => {
          commit(MutationTypes.SET_TEMPLATE_TREE, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TreeActions.GET_INVENTORY_CONFIGS](context: any) {
    return new Promise((resolve, reject) => {
      const iConfigs = context.state.inventoryConfigs;
      if (iConfigs != null && iConfigs.length > 0) {
        return resolve(iConfigs);
      }
      api.Staff.inventoryConfigs()
        .then((data: any) => {
          const configs = data;
          context.commit(MutationTypes.SET_INVENTORY_CONFIGS, configs);
          resolve(configs);
        })
        .catch((err) => {
          reject(err);
          return;
        });
    });
  },
  [TreeActions.LOAD_REPLIES]({ commit }) {
    return new Promise((resolve, reject) => {
      api.axios.get("/replies").then((response: any) => {
        commit("SET_REPLIES", response.data);
      });
    });
  },
  [TreeActions.LOAD_NEW_REPLIES]({ commit }) {
    return new Promise((resolve, reject) => {
      api.MessageTemplates.loadReplies()
        .then((response) => {
          commit(MutationTypes.SET_NEW_REPLIES, response);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [TreeActions.UPDATE_TREE]({ commit }, data: any) {
    return new Promise((resolve, reject) => {
      api.MessageTemplates.updateTemplate(data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [TreeActions.LOAD_SUBSTITUTE_VARS]({ commit }) {
    return new Promise((resolve, reject) => {
      api.MessageTemplates.getSubstituteVars()
        .then((response: any) => {
          commit(MutationTypes.SET_SUBSTITUTE_VARS, response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
