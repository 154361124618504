import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { TemplateTree } from "./types";

export const getters: GetterTree<TemplateTree, RootState> = {
  tree(state) {
    return state.tree;
  },
  getReply: state => (nodeKey: string) => {
    return state.replies[nodeKey];
  },
  getNewReply: state => (key: string) => {
    const obj = state.newReplies[key];
    const reply = obj.reply;
    return reply;
  },
  countReplies: state => {
    return Object.entries(state.replies).length;
  },
  accountAbbreviations: state => {
    const abbreviationArray: Array<string> = [];
    state.inventoryConfigs.forEach((item: any) => {
      abbreviationArray.push(item.account_abbrev);
    });
    return abbreviationArray;
  },
  accountUsernames: state => {
    const usernameArray: Array<string> = [];
    state.inventoryConfigs.forEach((item: any) => {
      usernameArray.push(item.username);
    });
    return usernameArray;
  }
};
