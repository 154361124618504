import axios from "axios";
import api from "@/api";

const REFRESH_TOKEN = "refreshToken";
const setToken = (
  token: string,
  exp: string,
  refreshToken?: string,
  kc?: string
) => {
  if (token) {
    localStorage.setItem("authToken", token);
    localStorage.setItem("exp", exp);
    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
    if (kc) {
      localStorage.setItem("kcToken", kc);
    }
  }
};
const getToken = (): string | null => {
  const token = localStorage.getItem("authToken");
  return token;
};
const getRefreshToken = (): string | null => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return refreshToken;
};

const removeToken = () => {
  localStorage.removeItem("authToken");
};

const getTokenExpiry = (): any | null => {
  const exp = localStorage.getItem("exp");
  if (exp) {
    const dateObj = Date.parse(exp);
    // const ndt = new Date(dateObj);
    // console.log(`Date type:${ndt}`);
    return dateObj;
  } else {
    return null;
  }
};

const tokenExpired = (): boolean | null => {
  const exp = getTokenExpiry();
  if (exp != null) {
    const currDate = Date.now();
    if (exp <= currDate) {
      return true;
    } else {
      return false;
    }
  }
  return null;
};
const refreshToken = (): Promise<string> => {
  const rToken = getRefreshToken();
  return new Promise((resolve, reject) => {
    if (rToken === null) {
      reject("No valid token");
      return;
    }
    api
      .refreshToken(axios, { refreshToken: rToken })
      .then((response: any) => {
        const token = response.data.token.token as string;
        const exp = response.data.token.expires as string;
        if (token) {
          setToken(token, exp);
          resolve(token);
          return;
        }
        reject("No token found.");
      })
      .catch((error: any) => {
        // remove the token
        localStorage.removeItem(REFRESH_TOKEN);
        reject(error);
      });
  });
};

export {
  setToken,
  getToken,
  removeToken,
  getTokenExpiry,
  tokenExpired,
  refreshToken,
  getRefreshToken,
};
