import AccountAbbrev from "@/services/AccountAbbrev";
import { DataState } from "@/services/data-states";
import { startOfISOWeek } from "date-fns";
import { fi } from "date-fns/locale";
import QSlideItem from 'quasar/src/components/slide-item/QSlideItem.js';;
import date from 'quasar/src/utils/date.js';;
import Vue from "vue";
import { MutationTree } from "vuex";
import { Message } from ".";
import { MessageMutations } from "./mutation-types";
import { MessageState } from "./types";

export const mutations: MutationTree<MessageState> = {
  [MessageMutations.ADD_TO_HANDLED_MESSAGES](state, messageId: string) {
    state.handledMessageIds.push(messageId);
  },
  [MessageMutations.ADD_LIST_TO_HANDLED_MESSAGES](
    state,
    messageIds: Array<string>
  ) {
    const result = state.handledMessageIds.concat(messageIds);
    state.handledMessageIds = result;
  },
  [MessageMutations.ADD_TO_READ_MESSAGES](state, id: string) {
    const isAlreadyReadCheck: boolean = state.readMessages.includes(id);
    if (!isAlreadyReadCheck) {
      state.readCount += 1;
      return state.readMessages.push(id);
    }
  },
  [MessageMutations.ADD_LIST_TO_READ_MESSAGES](state, ids: string) {
    const result = state.readMessages.concat(ids);
    state.readMessages = result;
  },
  [MessageMutations.SET_MESSAGES](state, payload: any) {
    if (payload != null) {
      state.messages = [];
      payload.forEach((element: any) => {
        let isRead = false;
        if (state.readMessages != null) {
          isRead = state.readMessages.includes(element.id);
          if (isRead) {
            state.readCount += 1;
          }
        }
        state.messages.push({
          dateReceived: date.extractDate(
            element.date_received,
            "D-MMM-YY hh:mm A"
          ),
          accountAbbrev: element.account_abbrev,
          hasOrder: element.has_order,
          id: element.id,
          hideFromFrontEnd: element.hide_from_frontend,
          name: element.name,
          orderId: element.order_id,
          subject: element.subject,
          thumbnail: element.thumbnail,
          url: element.url,
          username: element.username,
          wasOpened: false,
          isRead: isRead,
        });
      });

      state.messages.reverse();
      state.loadedMessages = true;
      state.filteredMessages = state.messages;
    }
  },
  [MessageMutations.CLEAR_STATE](state) {
    state.messages = [];
    state.handledMessageIds = [];
    state.loadedMessages = false;
    state.loadedTemplates = false;
    state.readMessages = [];
    state.quickTemplates = {};
    state.unreadCount = 0;
    state.readCount = 0;
  },
  [MessageMutations.SET_UNREAD_COUNT](state, count: number) {
    state.unreadCount = count;
  },
  [MessageMutations.SET_BUFFER](state, payload) {
    // state.bufferDetails.push(payload);
  },
  [MessageMutations.SET_BUFFER_STATE](state, payload: DataState) {
    state.bufferState = payload;
  },
  [MessageMutations.SET_BUFFER_LOADED](state, payload: boolean) {
    state.bufferLoaded = payload;
  },
  [MessageMutations.ADD_BUFFER_STEP](state) {
    state.bufferStep = state.bufferStep + 1;
  },
  [MessageMutations.SET_BUFFER_STEP](state, payload: number) {
    state.bufferStep = payload;
  },
  [MessageMutations.DELETE_MESSAGE](state, payload: string) {
    state.messages = state.messages.filter((item) => {
      return item.id != payload;
    });
    state.filteredMessages = state.filteredMessages.filter((item) => {
      return item.id != payload;
    });
  },
  [MessageMutations.RESET_BUFFER](state) {
    state.bufferDetails = {};
    state.bufferStep = 0;
    state.bufferLoaded = false;
    state.bufferLoading = false;
  },
  [MessageMutations.SET_BUFFER_LOADING](state, payload: boolean) {
    state.bufferLoading = payload;
  },
  [MessageMutations.DELETE_MESSAGE_BULK](state, payload: any) {
    for (const val of payload) {
      state.messages = state.messages.filter((item) => {
        return item.id != val.id;
      });
    }
  },
  [MessageMutations.ADD_QUICK_TEMPLATES](state, payload: any) {
    Vue.set(state.quickTemplates, payload.templateId, payload);
    // state.quickTemplates[payload.templateId] = payload;
  },
  [MessageMutations.SET_QUICK_TEMPLATES](state, payload: any) {
    state.quickTemplates = payload;
    state.loadedTemplates = true;
  },
  [MessageMutations.UPDATE_QUICK_TEMPLATES](state, payload: any) {
    const objectToUpdate = state.quickTemplates[payload.templateId];
    objectToUpdate["messageBody"] = payload.messageBody;
    objectToUpdate["label"] = payload.label;
  },
  [MessageMutations.DELETE_FROM_QUICK_TEMPLATES](state, payload: any) {
    Vue.delete(state.quickTemplates, payload);
  },
  [MessageMutations.SET_IS_NEXT_PAGE](state, payload: boolean) {
    state.isNextPage = payload;
  },
  [MessageMutations.SET_OUTBOUND_QUEUE](state, payload: any) {
    state.outboundQueue = payload;
  },
  [MessageMutations.SET_OUTBOUND_QUEUE_COUNT](state, payload: any) {
    state.unprocessedOutboundQueueCount = payload;
  },
  [MessageMutations.CLEAR_OUTBOUND_QUEUE](state) {
    state.outboundQueue = [];
  },
  [MessageMutations.SET_GLOSSARY](state, payload: any) {
    // payload.forEach((element: any) => {
    //   state.glossary.push({
    //     termId: element.termId,
    //     term: element.term,
    //     definition: element.definition,
    //   });
    // });
    state.glossary = payload;
    state.glossaryLoaded = true;
  },
  [MessageMutations.ADD_TO_GLOSSARY](state, payload: any) {
    state.glossary.push(payload);
  },
  [MessageMutations.UPDATE_GLOSSARY](
    state,
    payload: { termId: string; term: string; definition: string }
  ) {
    const indexOfObject = state.glossary.findIndex((obj) => {
      return obj.termId == payload.termId;
    });
    if (indexOfObject === -1) {
      console.log("could not update glossary");
      return;
    }
    Vue.set(state.glossary, indexOfObject, payload);
  },
  [MessageMutations.DELETE_GLOSSARY](state, payload: any) {
    state.glossary = state.glossary.filter((item) => {
      return item.termId != payload;
    });
  },
  [MessageMutations.FILTER_INBOX](state, filter: string | undefined) {
    if (filter != undefined) {
      state.currentInboxAccount = filter;
      const filteredMessages = state.messages.filter((item) => {
        return item.accountAbbrev == filter;
      });
      return (state.filteredMessages = filteredMessages);
    }
    return state.filteredMessages;
  },
  [MessageMutations.SET_INBOX_TYPE](state, inbox_type) {
    return (state.currentInboxType = inbox_type);
  },
  [MessageMutations.SET_CURRENT_ACCOUNT](state, currentAccountAbbrev) {
    return (state.currentInboxAccount = currentAccountAbbrev);
  },
};
