export enum MutationTypes {
  SET_TEMPLATE_TREE = "SET_TEMPLATE_TREE",
  SET_INVENTORY_CONFIGS = "SET_INVENTORY_CONFIGS",
  SET_REPLIES = "SET_REPLIES",
  SET_NEW_REPLIES = "SET_NEW_REPLIES",
  SET_SUBSTITUTE_VARS = "SET_SUBSTITUTE_VARS",
  UPDATE_SUBSTITUE_VARS = "UPDATE_SUBSTITUE_VARS",
  SET_INBOX_VEW = "SET_INBOX_VIEW",
  ACTION_POSTED = "ACTION_POSTED",
}
