export enum ActionTypes {
  INIT = "INIT",
  LOAD_MESSAGES = "LOAD_MESSAGES",
  LOAD_HANDLED_MESSAGES = "LOAD_HANDLED_MESSAGES",
  LOAD_READ_MESSAGES = "LOAD_READ_MESSAGES",
  HANDLE_MESSAGE = "HANDLE_MESSAGE",
  MARK_AS_READ = "MARK_AS_READ",
  SEND_TO_ADMIN = "SEND_TO_ADMIN",
  BULK_SEND_TO_ADMIN = "BULK_SEND_TO_ADMIN",
  MARK_AS_READ_BLUK = "MARK_AS_READ_BULK",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  DELETE_MESSAGE_BULK = "DELETE_MESSAGE_BULK",
  LOAD_QUICK_TEMPLATES = "LOAD_QUICK_TEMPLATES",
  UPDATE_QUICK_TEMPLATES = "UPDATE_QUICK_TEMPLATES",
  SAVE_QUICK_TEMPLATE = "SAVE_QUICK_TEMPLATE",
  DELETE_FROM_QUICK_TEMPLATES = "DELET_FROM_QUICK_TEMPLATES",
  LOAD_OUTBOUND_QUEUE = "LOAD_OUTBOUND_QUEUE",
  LOAD_GLOSSARY = "LOAD_GLOSSARY",
  SAVE_GLOSSARY = "SAVE_GLOSSARY",
  UPDATE_GLOSSARY = "UPDATE_GLOSSARY",
  DELETE_GLOSSARY = "DELETE_GLOSSARY"
}
export default {
  ActionTypes
};
