import BaseAxios from "axios";
import ShipsiteAuth from "@/api/shipping-api-cors/auth";
let source = "";
if (process.env.NODE_ENV === "development") {
  source = "http://0.0.0.0:8002";
} else {
  source = "https://shipping.celate.com";
}
function createAxiosInstance(baseURL: any) {
  const axios = BaseAxios.create({
    baseURL: source + baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("shipsiteToken");
    if (token) {
      config.headers.common["Authorization"] = `Token ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (
        error.response == null ||
        error.response == undefined ||
        error.response.status != 401
      ) {
        return Promise.reject(error);
      }
      localStorage.removeItem("shipsiteToken");
      return ShipsiteAuth.loginToShipsite()
        .then((responseData: any) => {
          const config = error.config;
          config.headers["Authorization"] = `Token ${responseData}`;
          return axios.request(config);
        })
        .catch((error) => {
          localStorage.removeItem("shipsiteToken");
        });
    }
  );

  return axios;
}

export default {
  createAxiosInstance,
};
