import staffApi from "@/api/staff";
import messageTemplatesApi from "@/api/message_templates";
import { ActionTree } from "vuex";
import { MessageMutations } from "./mutation-types";
import { ActionTypes } from "./action-types";
import { MessageState } from "./types";
import { RootState } from "@/store/types";
import { Message } from ".";
import { Warn } from "@/services/notif-utils";
import SSA from "@/services/session-helper";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
export const actions: ActionTree<MessageState, RootState> = {
  async [ActionTypes.INIT]({ dispatch }, filter: any) {
    await dispatch(ActionTypes.LOAD_READ_MESSAGES);
    await dispatch(ActionTypes.LOAD_MESSAGES, filter);
    await dispatch(ActionTypes.LOAD_HANDLED_MESSAGES);
    await dispatch(ActionTypes.LOAD_GLOSSARY);
  },
  [ActionTypes.HANDLE_MESSAGE]({ commit }, messageId: string) {
    commit(MessageMutations.ADD_TO_HANDLED_MESSAGES, messageId);
  },
  [ActionTypes.LOAD_HANDLED_MESSAGES]({ commit }) {
    return staffApi.getHandledMessages().then(messageIds => {
      commit(MessageMutations.ADD_LIST_TO_HANDLED_MESSAGES, messageIds);
    });
  },
  [ActionTypes.LOAD_READ_MESSAGES]({ commit }) {
    return staffApi.getReadMessages().then(response => {
      commit(
        MessageMutations.ADD_LIST_TO_READ_MESSAGES,
        response.data.message_ids
      );
    });
  },
  [ActionTypes.LOAD_MESSAGES]({ commit, state }, filter: any) {
    return new Promise((resolve, reject) => {
      staffApi
        .allUnread(filter)
        .then((payload: any) => {
          commit(MessageMutations.SET_MESSAGES, payload.messages);
          commit(MessageMutations.SET_UNREAD_COUNT, payload.unread_count);
          resolve(payload);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [ActionTypes.MARK_AS_READ]({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit(MessageMutations.ADD_TO_READ_MESSAGES, id);
      resolve(id);
    });
  },

  [ActionTypes.DELETE_MESSAGE]({ commit }, messageId) {
    // for (const item of messageIds) {
    commit(MessageMutations.DELETE_MESSAGE, messageId);
    // }
  },
  [ActionTypes.SEND_TO_ADMIN]({ commit }, id) {
    return new Promise((resolve, reject) => {
      SSA.setStatAction(SSC.SEND_TO_ADMIN).catch(error => {
        Warn("Unable to set Stat,Send to Admin");
      });
      staffApi
        .assignToAdmin(id)
        .then((payload: any) => {
          commit(MessageMutations.DELETE_MESSAGE, id);
          resolve(payload);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.SAVE_QUICK_TEMPLATE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .saveQuickTemplate(payload)
        .then((response: any) => {
          const data = response.data;
          commit(MessageMutations.ADD_QUICK_TEMPLATES, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.LOAD_QUICK_TEMPLATES]({ commit }) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .loadQuickTemplates()
        .then((data: any) => {
          commit(MessageMutations.SET_QUICK_TEMPLATES, data.quickTemplates);
          resolve(data.quickTemplates);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.UPDATE_QUICK_TEMPLATES]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .updateQuickTemplates(payload)
        .then(response => {
          commit(MessageMutations.UPDATE_QUICK_TEMPLATES, payload);
          resolve(payload);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //TODO: add this action
  [ActionTypes.DELETE_FROM_QUICK_TEMPLATES]({ commit }, templateId) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .deleteQuickTemplates(templateId)
        .then(response => {
          commit(MessageMutations.DELETE_FROM_QUICK_TEMPLATES, templateId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.LOAD_OUTBOUND_QUEUE]({ commit }, payload: any | undefined) {
    return new Promise((resolve, reject) => {
      let page = 1;
      let limit = 50;
      if (payload) {
        page = payload.page;
        limit = payload.rowsPerPage;
      }
      commit(MessageMutations.CLEAR_OUTBOUND_QUEUE);
      staffApi
        .outBoundQueue(page, limit)
        .then((response: any) => {
          commit(MessageMutations.SET_OUTBOUND_QUEUE, response.data.items);
          commit(
            MessageMutations.SET_OUTBOUND_QUEUE_COUNT,
            response.data.unprocessed_count
          );
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
    // TODO: CREATE ACTIONS FOR NEW GLOSSARY
  },
  [ActionTypes.LOAD_GLOSSARY]({ commit }) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .loadGlossary()
        .then(response => {
          commit(MessageMutations.SET_GLOSSARY, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.SAVE_GLOSSARY]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .saveGlossary(payload)
        .then(response => {
          commit(MessageMutations.ADD_TO_GLOSSARY, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.UPDATE_GLOSSARY]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .updateGlossary(payload)
        .then(response => {
          commit(MessageMutations.UPDATE_GLOSSARY, payload);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ActionTypes.DELETE_GLOSSARY]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      messageTemplatesApi
        .deleteGlossary(payload)
        .then(response => {
          commit(MessageMutations.DELETE_GLOSSARY, payload);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
