import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { AuthState } from "./types";
import { RootState } from "@/store/types";

export const state: AuthState = {
  user: {
    operatorName: "",
    isManager: false,
    username: "",
    sessionId: "",
    userId: "",
    shipsiteToken: "",
  },
  error: false,
};

const namespaced = true;
export const Auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
