import { render, staticRenderFns } from "./App.vue?vue&type=template&id=72bee34e"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"
import style0 from "./App.vue?vue&type=style&index=0&id=72bee34e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QSpinnerGears});
