/* eslint-disable camelcase */
import { Module } from "vuex";
import { CustomerState } from "./types";
import { RootState } from "../types";
import { mutations } from "./mutations";
import { actions } from "./actions";
export const state: CustomerState = {
  customers: [],
  tasks: [],
  tools: [],
  customersLoaded: false,
  tasksLoaded: false,
  toolsLoaded: false,
};

const namespaced = true;

export const Customer: Module<CustomerState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
};
