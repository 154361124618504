import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=51b2dff0&scoped=true"
import script from "./PageError.vue?vue&type=script&lang=ts"
export * from "./PageError.vue?vue&type=script&lang=ts"
import style0 from "./PageError.vue?vue&type=style&index=0&id=51b2dff0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b2dff0",
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QIcon});
