import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { AuthState } from "@/store/auth/types";

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn(state): boolean {
    if (state.user) {
      return true;
    }
    return false;
  },

  isManager(state): boolean {
    if (state.user?.isManager) {
      return true;
    }
    return false;
  },
  sessionId(state): string | undefined {
    return state.user?.sessionId;
  },
  userId(state): string | undefined {
    return state.user?.userId;
  }
};
