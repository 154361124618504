import Util from "./util";
import MAIN_API_UTILS from "@/api/util";
import StaffApi from "@/api/staff";
const SHIPSITE_API_URL = "/shipping";

const axios = Util.createAxiosInstance(SHIPSITE_API_URL);

export default {
  loginToShipsite() {
    return new Promise((resolve, reject) => {
      const data = {
        username: "robot",
        password: "r4BK8w9TVfrjqb",
      };
      const url = `/authenticate-token/`;
      axios
        .post(url, data)
        .then(async (response: any) => {
          const token = response.data["token"];
          try {
            await StaffApi.saveShipsiteTokenToCache(token);
            localStorage.setItem("shipsiteToken", token);
            resolve(token);
            return;
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },
};
