import { Module } from "vuex";
import { MessageState } from "./types";
import { RootState } from "@/store/types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { DataState } from "@/services/data-states";

export const state: MessageState = {
  messages: [],
  filteredMessages: [],
  isNextPage: false,
  handledMessageIds: [],
  loadedMessages: false,
  loadedTemplates: false,
  loadingIds: [],
  readMessages: [],
  quickTemplates: {},
  unreadCount: 0,
  readCount: 0,
  bufferLength: 0,
  bufferStep: 0,
  bufferLoaded: false,
  bufferState: DataState.INIT,
  bufferLoading: true,
  bufferDetails: {},
  outboundQueue: [],
  unprocessedOutboundQueueCount: 0,
  outboundLoaded: false,
  glossary: [],
  glossaryLoaded: false,
  currentInboxAccount: "KC",
  currentInboxType: "ManagerView",
};

const namespaced = true;
export const Message: Module<MessageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
