import { Module } from "vuex";
import { TemplateTree } from "./types";
import { RootState } from "@/store/types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: TemplateTree = {
  tree: [],
  replies: {},
  inventoryConfigs: [],
  newReplies: {},
  substituteVars: [{}],
  inboxType: "ManagerView",
  sessionStats: {},
  actionsPosted: 0,
};

const namespaced = true;

export const General: Module<TemplateTree, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
