import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { Auth } from "./auth/index";
import { Message } from "./messages/index";
import { General } from "./general/index";
import { Buffer } from "./buffer/index";
import { Customer } from "./customers";
Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {
//     version: "1.0.0",
//   },
//   mutations: {},
//   actions: {},
//   modules: {
//     auth,
//   },
// });

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
  },
  modules: {
    Auth,
    General,
    Message,
    Buffer,
    Customer,
  },
};
export default new Vuex.Store<RootState>(store);
