import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
import api from "@/api/manager";
import store from "@/store";
const setStatAction = (actionName: string) => {
  return new Promise((resolve, reject) => {
    const token = store.getters["Auth/sessionId"];
    const userId = store.getters["Auth/userId"];
    const data: { actionType: string; userId: string; sessionId?: string } = {
      actionType: actionName,
      userId: userId
    };
    if (token) {
      data["sessionId"] = token;
    }
    api
      .setStat(data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default { setStatAction };
