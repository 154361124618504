export enum MessageMutations {
  CLEAR_STATE = "CLEAR_STATE",
  SET_MESSAGES = "SET_MESSAGES",
  SET_UNREAD_COUNT = "SET_UNREAD_COUNT",
  ADD_TO_HANDLED_MESSAGES = "ADD_TO_HANDLED_MESSAGES",
  ADD_LIST_TO_HANDLED_MESSAGES = "ADD_LIST_TO_HANDLED_MESSAGES",
  ADD_LIST_TO_READ_MESSAGES = "ADD_LIST_TO_READ_MESSAGES",
  ADD_TO_READ_MESSAGES = "ADD_TO_READ_MESSAGES",
  MARK_AS_READ = "MARK_AS_READ",
  MARK_AS_UNREAD = "MARK_AS_UNREAD",
  MARK_AS_READ_BULK = "MARK_AS_READ_BLUK",
  DELETE_MESSAGE = "DELETE_MESSAGES",
  DELETE_MESSAGE_BULK = "DELETE_MESSAGE_BULK  ",
  ADD_QUICK_TEMPLATES = "ADD_QUICK_TEMPLATES",
  SET_QUICK_TEMPLATES = "SET_QUICK_TEMPLATES",
  UPDATE_QUICK_TEMPLATES = "UPDATE_QUICK_TEMPLATES",
  DELETE_FROM_QUICK_TEMPLATES = "DELET_FROM_QUICK_TEMPLATES",
  QUICK_TEMPLATE_CLICK = "QUICK_TEMPLATE_CLICK",
  SET_BUFFER_STATE = "SET_BUFFER_STATE",
  SET_BUFFER_LOADED = "SET_BUFFER_LOADED",
  SET_BUFFER_LOADING = "SET_BUFFER_LOADING",
  SET_BUFFER = "SET_BUFFER",
  SET_BUFFER_STEP = "SET_BUFFER_STEP",
  ADD_BUFFER_STEP = "ADD_BUFFER_STEP",
  ADD_TO_LOADING_IDS = "ADD_TO_LOADING_IDS",
  RESET_BUFFER = "RESET_BUFFER",
  SET_IS_NEXT_PAGE = "SET_IS_NEXT_PAGE",
  SET_OUTBOUND_QUEUE = "SET_OUTBOUND_QUEUE",
  SET_OUTBOUND_QUEUE_COUNT = "SET_OUTBOUND_QUEUE_COUNT",
  CLEAR_OUTBOUND_QUEUE = "CLEAR_OUTBOUND_QUEUE",
  FILTER_INBOX = "FILTER_INBOX",
  SET_GLOSSARY = "SET_GLOSSARY",
  UPDATE_GLOSSARY = "UPDATE_GLOSSARY",
  DELETE_GLOSSARY = "DELETE_GLOSSARY",
  ADD_TO_GLOSSARY = "ADD_TO_GLOSSARY",
  FILTER_MESSAGES = "FILTER_MESSAGES",
  SET_INBOX_TYPE = "SET_INBOX_TYPE",
  SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT",
}
