import BaseAxios from "axios";
import Staff from "./staff";
import Manager from "./manager";
import Util from "./util";
import MessageTemplates from "./message_templates";
const axios = Util.createAxiosInstance("/api");

export default {
  axios,
  BaseAxios,
  Staff,
  Manager,
  MessageTemplates,
  login(data: any) {
    return axios.post("/login", data);
  },
  getUser() {
    return axios.get("/user");
  },
  refreshToken(axiosInstance: any, payload: any) {
    // new axiosInstance needed so that it doesn't trigger the usual error handling that is normally used
    return axiosInstance.post("/api/refreshToken", payload);
  },

  getUUID(scope = "message") {
    return axios.get(`/getUUID/${scope}`).then((response) => {
      return response.data.uuid;
    });
  },
};
