import api from "@/api/index";
import staffApi from "@/api/staff";
import { ActionTree } from "vuex";
import { BufferMutations } from "./mutation-types";
import { ActionTypes } from "./action-types";
import { BufferState } from "./types";
import { RootState } from "@/store/types";
import staff from "@/api/staff";

export const actions: ActionTree<BufferState, RootState> = {};
