import api from "@/api";
import shipsiteApi from "@/api/shipping-api-cors/auth";
import { setToken } from "@/services/token-helper";
import { ActionTree } from "vuex";
import { AuthState } from "./types";
import { RootState } from "@/store/types";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import Mutation from 'quasar/src/directives/Mutation.js';;

function getPayload(responseData: any) {
  return {
    operatorName: responseData["operator_name"],
    isManager: responseData["is_manager"],
    username: responseData["username"],
    sessionId: responseData["session_id"],
    userId: responseData["user_id"],
  };
}

export const actions: ActionTree<AuthState, RootState> = {
  [ActionTypes.LOGIN_USER]({ commit }, data: any) {
    return new Promise((resolve, reject) => {
      api
        .login(data)
        .then((response: any) => {
          const payload = getPayload(response.data);
          commit(MutationTypes.SET_AUTH_INFO, payload);
          setToken(
            response.data.token.token,
            response.data.token.expires,
            response.data.refresh_token.token
          );
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ActionTypes.SWITCH_UNIVERSAL_TOKEN_FOR_LOCAL]({ commit }, token: any) {
    return new Promise((resolve, reject) => {
      api.BaseAxios.get("/api/switchUniversalTokenForLocal", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          const payload = getPayload(response.data);
          commit(MutationTypes.SET_AUTH_INFO, payload);
          setToken(
            response.data.token.token,
            response.data.token.expires,
            response.data.refresh_token.token,
            response.data.special_token
          );
          resolve(response.data);
          return;
        })
        .catch((err) => {
          reject(err);
          return;
        });
    });
  },
  [ActionTypes.LOGOUT_USER]({ commit }) {
    return new Promise((resolve, reject) => {
      resolve(commit(MutationTypes.LOGOUT_USER));
      reject(Error);
    });
  },
  [ActionTypes.GET_USER]({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getUser()
        .then(({ data }) => {
          const payload = {
            operatorName: data["operator_name"],
            isManager: data["is_manager"],
            username: data["username"],
            userId: data["id"],
            sessionId: data["session_id"],
          };
          commit(MutationTypes.SET_AUTH_INFO, payload);
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ActionTypes.SET_SESSION_TOKEN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.Manager.setStat(payload)
        .then((response: any) => {
          const token = response.data.token;
          commit(MutationTypes.SET_SESSION_TOKEN, token);
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // [ActionTypes.GET_SHIPSITE_TOKEN]({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     api.Staff.fetchShipsiteToken()
  //       .then((response: any) => {
  //         console.log(response);
  //         let token = response;
  //         console.log(response);
  //         if (!token) {
  //           shipsiteApi
  //             .loginToShipsite()
  //             .then(async (responseData: any) => {
  //               token = responseData.token;
  //               try {
  //                 await api.Staff.saveShipsiteTokenToCache(token);
  //               } catch {
  //                 console.log("Unable to save token for shipsite");
  //                 return;
  //               }
  //             })
  //             .catch((error) => {
  //               console.log("Unable to login to shipsite and generate Tokne");
  //               reject(error);
  //               return;
  //             });
  //         }
  //         commit(MutationTypes.SET_SHIPSITE_TOKEN, token);
  //         resolve(token);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
};
