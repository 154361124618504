import Vue from "vue";
import App from "./App.vue";
import api from "@/api";
import router from "./router";
import store from "./store";
import "./quasar";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.config.productionTip = false;
Vue.use(Chartkick.use(Chart));

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = window.localStorage.getItem("authToken");
    if (!token) {
      localStorage.setItem("next", to.path);
      next({
        name: "Login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
