import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "mainLayout" */ "../layout/MainLayout.vue"),
    meta: { requiresAuth: true },

    children: [
      {
        path: "",
        redirect: { name: "Unread" },
      },
      {
        path: "unread",
        component: () =>
          import(/* webpackChunkName: "unread" */ "../views/Unread.vue"),
        name: "Unread",
        meta: { requiresAuth: true },
      },
      {
        path: "outbound",
        component: () =>
          import(/* webpackChunkName: "inbox" */ "../views/OutboundQueue.vue"),
        name: "OutboundQueue",
        meta: { requiresAuth: true },
      },
      {
        path: "sent",
        component: () =>
          import(/* webpackChunkName: "sent" */ "../views/Sent.vue"),
        name: "Sent",
        meta: { requiresAuth: true },
      },
      {
        path: "stats",
        component: () =>
          import(/* webpackChunkName: "stats" */ "../views/Stats.vue"),
        name: "Stats",
        meta: { requiresAuth: true },
      },
      {
        path: "admin-settings",
        component: () =>
          import(
            /* webpackChunkName: "adminsettings" */ "../views/AdminSettings.vue"
          ),
        name: "AdminSettings",
        meta: { requiresAuth: true },
      },
      {
        path: "message-detail/:messageId",
        component: () =>
          import(/* webpackChunkName: "trash" */ "../views/MessageDetail.vue"),
        name: "MessageDetail",
        meta: { requiresAuth: true },
      },
      {
        path: "order-detail/:orderId",
        component: () =>
          import(/*webpackChunkName:"orderDetail"*/ "../views/OrderDetail.vue"),
        name: "OrderDetail",
        meta: { requiresAuth: true },
      },
      {
        path: "vip-customers/",
        component: () =>
          import(
            /*webpackChunkName:"vip-customers" */ "../views/VIPCustomers.vue"
          ),
        name: "VIPCustomers",
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
