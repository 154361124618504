import BaseAxios from "axios";
import Util from "./util";

const axios = Util.createAxiosInstance("/api/message_templates");

export default {
  addTemplates(data: any) {
    console.log("Managed to reach the api TS code");
    return new Promise((resolve, reject) => {
      axios
        .post("/add_template", { templates: data })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadReplies() {
    return new Promise((resolve, reject) => {
      const url = "/get_replies";
      axios
        .get(url)
        .then((response) => {
          resolve(response.data.replies);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addReply(data: any) {
    return new Promise((resolve, reject) => {
      axios
        .post("/add_replies", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateReply(data: any) {
    return new Promise((resolve, reject) => {
      const url = "/update_replies";
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTemplate(data: any) {
    const nodeKey = data.nodeId;
    return new Promise((resolve, reject) => {
      const url = `/update_template/${nodeKey}`;
      axios
        .post(url, { node: data })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTree() {
    return new Promise((resolve, reject) => {
      const url = `/tree`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveNewTemplate(data: any) {
    return new Promise((resolve, reject) => {
      const url = `/add_new_template`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteNode(data: any) {
    return new Promise((resolve, reject) => {
      const url = `/delete_template`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addNewCategory(data: any) {
    return new Promise((resolve, reject) => {
      const url = "/add_category";
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSubstituteVars() {
    return new Promise((resolve, reject) => {
      const url = `/substitute_vars`;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveQuickTemplate(data: { label: string; messageBody: string }) {
    return new Promise((resolve, reject) => {
      const url = `/quick_templates`;
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadQuickTemplates() {
    return new Promise((resolve, reject) => {
      const url = "/quick_templates";
      axios
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateQuickTemplates(data: {
    templateId: string;
    label: string;
    messageBody: string;
  }) {
    return new Promise((resolve, reject) => {
      const url = `/quick_templates/${data.templateId}`;
      axios
        .put(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteQuickTemplates(templateId: string) {
    return new Promise((resolve, reject) => {
      const url = `/quick_templates/${templateId}`;
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadGlossary() {
    return new Promise((resolve, reject) => {
      const url = "/glossary";
      axios
        .get(url)
        .then((response) => {
          resolve(response.data.glossary);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveGlossary(data: { term: string; definition: string }) {
    return new Promise((resolve, reject) => {
      const url = "/glossary";
      axios
        .post(url, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateGlossary(data: { termId: string; term: string; definition: string }) {
    return new Promise((resolve, reject) => {
      const url = `/glossary/${data.termId}`;
      axios
        .put(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteGlossary(termId: string) {
    return new Promise((resolve, reject) => {
      const url = `/glossary/${termId}`;
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTreeForCSVParser() {
    return new Promise((resolve, reject) => {
      const url = `/tree-for-csv`;
      axios
        .get(url)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
