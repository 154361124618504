import api from "@/api/shipping-api-cors/customers";
import { ActionTree } from "vuex";
import { CustomerActionTypes } from "./action-types";
import { CustomerMutationTypes } from "./mutation-types";
import { RootState } from "../types";
import { CustomerState } from "./types";

export const actions: ActionTree<CustomerState, RootState> = {
  [CustomerActionTypes.CREATE_CUSTOMER]({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createCustomer(data)
        .then((responseData: any) => {
          // const data = responseData.results;
          // commit(CustomerMutationTypes.ADD_CUSTOMER, data);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.LOAD_CUSTOMERS]({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .loadCustomers()
        .then((responseData: any) => {
          // const data = responseData.data;
          commit(CustomerMutationTypes.SET_CUSTOMERS, responseData.results);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.UPDATE_CUSTOMER]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      api
        .updateCustomer(payload)
        .then((responseData: any) => {
          commit(CustomerMutationTypes.UPDATE_CUSTOMERS, payload);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.DELETE_CUSTOMER]({ commit }, customerId) {
    return new Promise((resolve, reject) => {
      api
        .deleteCustomer(customerId)
        .then((response) => {
          commit(CustomerMutationTypes.REMOVE_CUSTOMER, customerId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.CREATE_TASK]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      api
        .createTask(payload)
        .then((responseData: any) => {
          commit(CustomerMutationTypes.ADD_TASK, payload);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.LOAD_TASKS]({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .loadTasks()
        .then((responseData: any) => {
          commit(CustomerMutationTypes.SET_TASKS, responseData.results);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.UPDATE_TASK]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateTask(payload)
        .then((response) => {
          commit(CustomerMutationTypes.UPDATE_TASK, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.DELETE_TASK]({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      api
        .deleteTask(taskId)
        .then((response) => {
          commit(CustomerMutationTypes.REMOVE_TASK, taskId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // TOOL ACTIONS
  [CustomerActionTypes.CREATE_TOOL]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      api
        .createTool(payload)
        .then((responseData: any) => {
          commit(CustomerMutationTypes.ADD_TOOL, payload);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.LOAD_TOOLS]({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .loadTools()
        .then((responseData: any) => {
          commit(CustomerMutationTypes.SET_TOOLS, responseData.results);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.UPDATE_TOOL]({ commit }, payload: any) {
    return new Promise((resolve, reject) => {
      api
        .updateTool(payload)
        .then((response) => {
          commit(CustomerMutationTypes.UPDATE_TOOL, payload);
          resolve(payload);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CustomerActionTypes.DELETE_TOOL]({ commit }, toolId) {
    return new Promise((resolve, reject) => {
      api
        .deleteTool(toolId)
        .then((response) => {
          commit(CustomerMutationTypes.REMOVE_TOOL, toolId);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
