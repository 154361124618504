import { Module } from "vuex";
import { BufferState } from "./types";
import { RootState } from "@/store/types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { DataState } from "@/services/data-states";

export const state: BufferState = {
  currIndex: 0,
  endIndex: 6,
  currentlyOpenId: "",
  currentlyLoadingIds: new Map(),
  // isNextPage: false,
  bufferLength: 0,
  bufferLoadingError: false,
  bufferErrorMessage: "",
  bufferStep: 0,
  bufferLoaded: false,
  bufferState: DataState.INIT,
  bufferLoading: true,
  bufferDetails: {},
  errorLoadingIds: {}
};

const namespaced = true;
export const Buffer: Module<BufferState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
