export enum BufferMutations {
  SET_BUFFER_STATE = "SET_BUFFER_STATE",
  SET_BUFFER_LOADED = "SET_BUFFER_LOADED",
  SET_BUFFER_LOADING = "SET_BUFFER_LOADING",
  SET_BUFFER_ERROR_MESSAGE = "SET_BUFFER_ERROR_MESSAGE",
  SET_BUFFER_ERROR_STATE = "SET_BUFFER_ERROR_STATE",
  SET_BUFFER = "SET_BUFFER",
  SET_BUFFER_STEP = "SET_BUFFER_STEP",
  ADD_BUFFER_STEP = "ADD_BUFFER_STEP",
  ADD_TO_LOADING_IDS = "ADD_TO_LOADING_IDS",
  REMOVE_FROM_LOADING_IDS = "REMOVE_FROM_LOADING_IDS",
  RESET_BUFFER = "RESET_BUFFER",
  SET_IS_NEXT_PAGE = "SET_IS_NEXT_PAGE",
  SET_START_END_INDEX = "SET_START_END_INDEX",
  SET_START_INDEX = "SET_START_INDEX"
}
