export enum SessionActionNames {
  START = "session_start",
  END = "session_end",
  REPLY = "reply",
  TEMPLATE_REPLY = "template_reply",
  SEND_DELETE = "send_delete",
  DELETE = "delete",
  SEND_TO_ADMIN = "send_to_admin",
  CLIENT_DATE_MASK = "yyyy/MM/dd",
  SERVER_DATE_MASK = "%y-%m-%d %h:%m:%s.%f"
}
