import Util from "./util";
const url = "/customers/api/";
const axios = Util.createAxiosInstance(url);
export default {
  createCustomer(payload: any) {
    return new Promise((resolve, reject) => {
      const url = "/customer/";
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCustomer(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/customer/${payload.id}/`;
      axios
        .patch(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCustomers() {
    return new Promise((resolve, reject) => {
      const url = `/customer/`;
      axios
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCustomer(customerId: any) {
    return new Promise((resolve, reject) => {
      const url = `/customer/${customerId}/`;
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadTasks() {
    return new Promise((resolve, reject) => {
      const url = `/task`;
      axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  createTask(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/task/`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTask(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/task/${payload.id}/`;
      axios
        .patch(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTask(taskId: any) {
    return new Promise((resolve, reject) => {
      const url = `/task/${taskId}/`;
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTool(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/tool/`;
      axios
        .post(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadTools() {
    return new Promise((resolve, reject) => {
      const url = `/tool`;
      axios
        .get(url)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  updateTool(payload: any) {
    return new Promise((resolve, reject) => {
      const url = `/tool/${payload.id}/`;
      axios
        .patch(url, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTool(toolId: any) {
    return new Promise((resolve, reject) => {
      const url = `/tool/${toolId}/`;
      axios
        .delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
