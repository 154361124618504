import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { TemplateTree } from "./types";

export const mutations: MutationTree<TemplateTree> = {
  [MutationTypes.SET_TEMPLATE_TREE](state, payload: any) {
    if (payload != null || payload.length == 0) {
      state.tree = payload.categories;
    }
  },
  [MutationTypes.SET_REPLIES](state, payload: any) {
    state.replies = payload;
  },
  [MutationTypes.SET_NEW_REPLIES](state, payload: any) {
    state.newReplies = payload;
  },
  [MutationTypes.SET_INVENTORY_CONFIGS](state, payload: any) {
    state.inventoryConfigs = payload;
  },
  [MutationTypes.SET_SUBSTITUTE_VARS](state, payload: any) {
    state.substituteVars = payload;
  },
  [MutationTypes.SET_INBOX_VEW](
    state,
    payload: "ManagerView" | "StaffView" | "AllView"
  ) {
    state.inboxType = payload;
  },
  [MutationTypes.ACTION_POSTED](state) {
    state.actionsPosted = state.actionsPosted + 1;
  },
};
